import React from 'react';
import ReactDOM from 'react-dom';
import {HomePage} from "./pages/home-page";
import * as serviceWorker from './serviceWorker';
import './index.css';
import './assets/fonts/product-sans.css';

ReactDOM.render(
  <React.StrictMode>
    <HomePage />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
