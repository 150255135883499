import React from 'react';
import {Typography} from '@material-ui/core'

import '../assets/fonts/product-sans.css';

enum Theme {
  Light,
  Dark,
}

enum Font {
  ProductSans,
  Roboto,
}

type TextProps = {
  theme: Theme,
  font: Font,
  variant: any,
  children: any,
  style?: any,
};

const Text = (props: TextProps) => {
  let color = '';
  switch (props.theme) {
    case Theme.Dark:
      color = '#000000';
      break;
    case Theme.Light:
      color = '#FFFFFF';
      break;
  }

  let fontFamily = '';
  switch (props.font) {
    case Font.ProductSans:
      fontFamily = 'Product Sans';
      break;
    case Font.Roboto:
      fontFamily = 'Roboto';
      break;
  }


  return (
      <Typography
          style={{ color, fontFamily, ...props.style }}
          variant={props.variant}
      >
        {props.children}
      </Typography>
  );
};

export {Theme, Font, Text};
