import React, {RefObject} from 'react';
import {Avatar, Fab} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {SocialIcon} from 'react-social-icons';
import {Timeline} from '@material-ui/lab';
import {isMobile} from 'react-device-detect';

import {ReactComponent as CarrotLogo} from '../assets/images/carrot-logo.svg';
import {ReactComponent as CitadelSecuritiesLogo} from '../assets/images/citadel-securities-logo.svg';
import {ReactComponent as GoogleLogo} from '../assets/images/google-logo.svg';
import {ReactComponent as HatchLogo} from '../assets/images/hatch-logo.svg';
import {ReactComponent as LuminaLogo} from '../assets/images/lumina-logo.svg';
import {ReactComponent as NascentLogo} from '../assets/images/nascent-logo.svg';
import {CompleteTimelineItem, Font, Text, Theme} from '../components';
import {Colors, Strings} from '../values';

class HomePage extends React.Component<{}, {}> {
  private aboutMeRef: RefObject<HTMLDivElement> =
      React.createRef<HTMLDivElement>();

  render() {
    let aboutMeStyle = {};
    let avatarStyle = {};
    if (isMobile) {
      aboutMeStyle = styles.aboutMeMobile;
      avatarStyle = styles.avatarMobile;
    } else {
      aboutMeStyle = { ...styles.horizontalFlex, ...styles.aboutMeWeb };
      avatarStyle = styles.avatarWeb;
    }

    return (
        <div style={styles.background}>
          <div
              style={{
                ...styles.section,
                height: '100vh',
                background: Colors.primary
              }}
          >
            <div style={{ flex: 1 }} />
            <Text
                theme={Theme.Light}
                font={Font.ProductSans}
                variant={isMobile ? 'h3' : 'h2'}
            >
              ERIK TERWIEL
            </Text>
            <a
                target='_blank'
                href='erik-terwiel-resume-4A-4B.pdf'
                style={{ textDecoration: 'none' }}
            >
              <Text
                  theme={Theme.Light}
                  font={Font.ProductSans}
                  variant='body1'
                  style={{ marginTop: 12, marginBottom: 12 }}
              >
                RESUME
              </Text>
            </a>
            <div style={{ ...styles.horizontalFlex }}>
              <SocialIcon
                  url={Strings.urlGithub}
                  fgColor={Colors.white}
                  bgColor={Colors.primary}
                  target='_blank'
              />
              <SocialIcon
                  url={Strings.urlLinkedin}
                  fgColor={Colors.white}
                  bgColor={Colors.primary}
                  target='_blank'
              />
              <SocialIcon
                  url={Strings.urlEmail}
                  fgColor={Colors.white}
                  bgColor={Colors.primary}
                  target='_blank'
              />
            </div>
            <div style={{ flex: 1 }} />
            <Fab
                size='small'
                onClick={() =>
                    window.scrollTo(0, this.aboutMeRef.current!.offsetTop)}
                style={{
                  background: Colors.white,
                  marginBottom: 64,
                }}
            >
              <ExpandMore />
            </Fab>
          </div>

          <div
              ref={this.aboutMeRef}
              style={{
                ...styles.section,
                paddingTop: 48,
                paddingBottom: 48,
                background: Colors.white,
              }}
          >
            <Text
                theme={Theme.Dark}
                font={Font.ProductSans}
                variant='h4'
                style={{ marginBottom: 48 }}
            >
              {Strings.aboutMeTitle}
            </Text>
            <div style={aboutMeStyle}>
              <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
              >
                <Avatar
                    src='headshot.jpg'
                    style={avatarStyle}
                />
              </div>
              <div style={{ flex: 1, background: 'white' }}>
                {Strings.aboutMeBody.split('\n').map((item, i) =>
                    <Text
                        theme={Theme.Dark}
                        font={Font.Roboto}
                        variant='subtitle1'
                        style={{ marginBottom: 16 }}
                    >
                      {item}
                    </Text>
                )}
              </div>
            </div>
          </div>

          <div
              style={{
                ...styles.section,
                paddingTop: 48,
                paddingBottom: 48,
                background: Colors.gray,
              }}
          >
            <Text
                theme={Theme.Dark}
                font={Font.ProductSans}
                variant='h4'
                style={{ marginBottom: 48 }}
            >
              {Strings.experienceTitle}
            </Text>
            <Timeline
                align={isMobile ? 'left' : 'alternate'}
                style={{
                  paddingLeft: isMobile ? 0 : 176,
                  paddingRight: isMobile ? 0 : 176,
                }}
            >
              <CompleteTimelineItem
                  company={Strings.citadelSecuritiesCompany}
                  position={Strings.citadelSecuritiesPosition}
                  date={Strings.citadelSecuritiesDate}
                  location={Strings.citadelSecuritiesLocation}
                  description={Strings.citadelSecuritiesDescription}
                  url={Strings.citadelSecuritiesUrl}
                  showConnector={true}
              >
                <CitadelSecuritiesLogo style={styles.logo} />
              </CompleteTimelineItem>
              <CompleteTimelineItem
                  company={Strings.hatchCompany}
                  position={Strings.hatchPosition}
                  date={Strings.hatchDate}
                  location={Strings.hatchLocation}
                  description={Strings.hatchDescription}
                  url={Strings.hatchUrl}
                  showConnector={true}
              >
                <HatchLogo style={styles.logo} />
              </CompleteTimelineItem>
              <CompleteTimelineItem
                  company={Strings.googleCompany}
                  position={Strings.googlePosition}
                  date={Strings.googleDate}
                  location={Strings.googleLocation}
                  description={Strings.googleDescription}
                  url={Strings.googleUrl}
                  showConnector={true}
              >
                <GoogleLogo style={styles.logo} />
              </CompleteTimelineItem>
              <CompleteTimelineItem
                  company={Strings.luminaCompany}
                  position={Strings.luminaPosition}
                  date={Strings.luminaDate}
                  location={Strings.luminaLocation}
                  description={Strings.luminaDescription}
                  url={Strings.luminaUrl}
                  showConnector={true}
              >
                <LuminaLogo style={styles.logo} />
              </CompleteTimelineItem>
              <CompleteTimelineItem
                  company={Strings.carrotCompany}
                  position={Strings.carrotPosition}
                  date={Strings.carrotDate}
                  location={Strings.carrotLocation}
                  description={Strings.carrotDescription}
                  url={Strings.carrotUrl}
                  showConnector={true}
              >
                <CarrotLogo style={styles.logo} />
              </CompleteTimelineItem>
              <CompleteTimelineItem
                  company={Strings.nascentCompany}
                  position={Strings.nascentPosition}
                  date={Strings.nascentDate}
                  location={Strings.nascentLocation}
                  description={Strings.nascentDescription}
                  url={Strings.nascentUrl}
                  showConnector={false}
              >
                <NascentLogo style={{ width: 24, height: 24, padding: 4 }} />
              </CompleteTimelineItem>
            </Timeline>
          </div>

          <div
              style={{
                ...styles.section,
                backgroundColor: Colors.white,
                paddingTop: 48,
                paddingBottom: 48,
              }}
          >
            <Text
                theme={Theme.Dark}
                font={Font.ProductSans}
                variant='h4'
                style={{ marginBottom: 48 }}
            >
              {Strings.travelTitle}
            </Text>
            <Text theme={Theme.Dark} font={Font.Roboto} variant='subtitle1'>
              {Strings.travelBody}
            </Text>
          </div>

        </div>
    );
  }
}

const styles = {
  background: {
    position: 'absolute' as 'absolute',
    top: 0,
    right: 0,
    left: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row' as 'row',
  },
  aboutMeWeb: {
    paddingLeft: 192,
    paddingRight: 192,
  },
  aboutMeMobile: {
    marginLeft: 16,
    paddingRight: 16,
  },
  avatarWeb: {
    width: '18vw',
    height: '18vw',
  },
  avatarMobile: {
    width: '80%',
    height: '80%',
    marginBottom: 48,
  },
  logo: {
    width: 32,
    height: 32,
  },
};

export {HomePage};
