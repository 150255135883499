import React from 'react';
import {
  TimelineConnector, TimelineContent,
  TimelineDot, TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import {Font, Text, Theme} from './text';
import {Colors} from '../values';
import {Paper} from '@material-ui/core';

type TimelineItemProps = {
  company: string,
  position: string,
  date: string,
  location: string,
  description: string,
  url: string,
  showConnector: boolean,
  children: any,
};

const CompleteTimelineItem = (props: TimelineItemProps) =>
    <TimelineItem>
      <TimelineOppositeContent>
        <Text
            theme={Theme.Dark}
            font={Font.ProductSans}
            variant='body1'
        >
          {props.date}
        </Text>
        <Text
            theme={Theme.Dark}
            font={Font.ProductSans}
            variant='body1'
        >
          {props.location}
        </Text>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ background: Colors.white }}>
          {props.children}
        </TimelineDot>
        {props.showConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <a target='_blank' rel='noopener noreferrer' href={props.url} style={{ textDecoration: 'none' }}>
          <Paper elevation={1} style={{ padding: 32 }}>
            <Text
                theme={Theme.Dark}
                font={Font.ProductSans}
                variant='h6'
            >
              {props.company}
            </Text>
            <Text
                theme={Theme.Dark}
                font={Font.ProductSans}
                variant='h6'
            >
              {props.position}
            </Text>
            <Text
                theme={Theme.Dark}
                font={Font.Roboto}
                variant='body1'
            >
              {props.description}
            </Text>
          </Paper>
        </a>
      </TimelineContent>
    </TimelineItem>;

export {CompleteTimelineItem};
