const Strings = {
  urlLinkedin: 'https://www.linkedin.com/in/erikterwiel/',
  urlGithub: 'https://github.com/erikterwiel',
  urlEmail: 'mailto:erik.terwiel@uwaterloo.ca',
  aboutMeTitle: 'ABOUT ME',
  aboutMeBody:
      'Hello, my name is Erik and welcome to my website. I\'m currently a 4A (Senior) Software Engineering and Computational Mathematics Student at the University of Waterloo. I\'m also a well-versed Software Engineer with a strong passion for trading financial markets.\n' +
      'Recently I\'ve wrapped up an internship at Citadel Securities on the Single Stock Options and Systematic FICC desks where I worked as a Trader Intern. Prior to Citadel Securities, I interned as a Backend Engineer at Hatch, Google, and Lumina. Prior to Lumina, I\'ve attempted to launch my own marketplace startup (failed) and interned two more times as a Mobile Engineer.\n' +
      'These days outside of school, trading, and working on projects, my time is spent going to the gym, hiking, driving at night, and listening to electronic music. I also really enjoy travelling to unique places and experiencing foreign cultures.',
  experienceTitle: 'EXPERIENCE',
  citadelSecuritiesCompany: 'Citadel Securities',
  citadelSecuritiesPosition: 'Trader Intern, Single Stock Options, Systematic FICC',
  citadelSecuritiesDate: 'Jun 2021 - Aug 2021',
  citadelSecuritiesLocation: 'Chicago, IL',
  citadelSecuritiesUrl: 'https://www.citadelsecurities.com/',
  citadelSecuritiesDescription: 'Researched equity options market for inefficiencies and predictive signals using Python and Pandas. Developed Python batch data processing pipeline which identifies patterns and detects anomalies in FICC market events.',
  hatchCompany: 'Hatch',
  hatchPosition: 'Software Engineer Intern, Backend',
  hatchDate: 'Jan 2021 - Jun 2021',
  hatchLocation: 'San Francisco, CA',
  hatchUrl: 'https://www.hatchcard.com/',
  hatchDescription: 'Developed core banking features such as debit card overdraft, monthly statements, and fees using Kotlin and PostgreSQL. Instrumented 3rd-party integrations and wrote incident response procedures using heartbeat metrics, monitors, runbooks.',
  googleCompany: 'Google',
  googlePosition: 'Software Engineer Intern, Backend',
  googleDate: 'May 2020 - Aug 2020',
  googleLocation: 'Mountain View, CA',
  googleUrl: 'https://www.google.com/travel/hotels',
  googleDescription: 'Architected and built Apache Beam Go pipeline that computes global hotel and regional safety scores from historical crime data. Developed multithreaded C++ job and backend API that persists pipeline results from Memcached as serialized Protobuf and exposes the cached results.',
  luminaCompany: 'Lumina',
  luminaPosition: 'Software Engineer Intern, Backend',
  luminaDate: 'Sep 2019 - Dec 2019',
  luminaLocation: 'San Francisco, CA',
  luminaUrl: 'https://lumina.app/',
  luminaDescription: 'Developed unified data and accounting model for crypto derivative trades with support for options, futures, and CFDs. Integrated crypto exchanges and blockchains with Go backend, resulting in added ~1M line items and >$2B in digital assets.',
  carrotCompany: 'Carrot Inc.',
  carrotPosition: 'Software Engineer Intern, Mobile',
  carrotDate: 'Jan 2019 - Apr 2019',
  carrotLocation: 'Redwood City, CA',
  carrotUrl: 'https://carrot.co/',
  carrotDescription: 'Developed Pivot, an Android app integrated with a Bluetooth LE device which empowers millions of people to quit smoking. Revamped or completely reconstructed nearly every feature to adhere to MVVM architecture and latest Android standards.',
  nascentCompany: 'Nascent',
  nascentPosition: 'Software Developer Intern, Mobile',
  nascentDate: 'May 2018 - Aug 2018',
  nascentLocation: 'Toronto, ON',
  nascentUrl: 'https://nascentdigital.com/',
  nascentDescription: 'Developed Can-Am Ryker Ride Builder, a React Native AR app built with native iOS ARKit and Android ARCore modules. Built Carmoji, a React Native mobile app based on reactive programming principals and an Express, MongoDB backend.',
  travelTitle: 'ADVENTURES',
  travelBody: 'Work in Progress Coming Soon',
};

export {Strings};
